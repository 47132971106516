import * as React from 'react'
import { createContainer } from 'unstated-next'

import type { EntryExit } from '@/types/entryExit'
import type { SiteComment } from '@/types/siteComment'
import type { UserComment } from '@/types/userComment'

interface InitialStateType {
  isOnline: boolean
  siteComments: SiteComment[] | null
  entryExits: EntryExit[] | null
  userComments: UserComment[] | null
}

const initialState = {
  isOnline: false,
  siteComments: null,
  entryExits: null,
  userComments: null,
} as const

const useAppState = (state: InitialStateType = initialState) => {
  const [isOnline, setIsOnline] = React.useState<InitialStateType['isOnline']>(
    state.isOnline ?? initialState.isOnline,
  )

  const [siteComments, setSiteComments] = React.useState<
    InitialStateType['siteComments']
  >(state.siteComments ?? initialState.siteComments)

  const [entryExits, setEntryExits] = React.useState<
    InitialStateType['entryExits']
  >(state.entryExits ?? initialState.entryExits)
      
  const [userComments, setUserComments] = React.useState<
  InitialStateType['userComments']
  >(state.userComments ?? initialState.userComments)

  const clearState = () => {
    setSiteComments(initialState.siteComments)
    setUserComments(initialState.userComments)
    setEntryExits(initialState.entryExits)
  }

  const isDataPending =
    (siteComments && siteComments?.length > 0) ||
    (entryExits && entryExits?.length > 0) ||
    (userComments && userComments?.length > 0) || false

  return {
    isOnline,
    setIsOnline,
    siteComments,
    setSiteComments,
    entryExits,
    setEntryExits,
    userComments,
    setUserComments,
    isDataPending,
    clearState,
  }
}

export const AppStateContainer = createContainer(useAppState)
